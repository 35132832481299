import React from "react"
import { useCookies } from "utils/useCookies"

import styled from "styled-components"

const CookieBar = () => {
	const { acceptCookies, rejectCookies, displayBar, cookieText } = useCookies()

	return (
		<>{displayBar && <Bar>
			<Container>
				<TextContainer>
					<p dangerouslySetInnerHTML={{ __html: cookieText }} />
				</TextContainer>
				<ButtonContainer>
					<button onClick={acceptCookies}>Accept</button>
					<button onClick={rejectCookies}>Reject</button>
				</ButtonContainer>
			</Container>
		</Bar>}
		</>
	)
}

const TextContainer = styled.div`
	flex: 1;
	margin: auto;

	@media (max-width: 800px) {
		width: 100%;
		margin-bottom: 2rem;
	}

	a {
		text-decoration: underline;
	}
`

const Container = styled.div`
display: flex;
flex-wrap: wrap;
align-items: center;
width: 100%;
max-width: 90rem;
margin: auto;
`

const ButtonContainer = styled.div`
	@media (max-width: 800px) {
		width: 100%;
		text-align: center;
	}

	button {
		width: 120px;
		padding: 1rem 0;
		text-align: center;
		border: 1px solid #645B9B;
		margin-left: 1rem;
		border-radius: 5px;

		&:first-of-type {
			background: #645B9B;
		}
	}

	@media (max-width: 750px) {
		display: flex;
		justify-content: space-between;
		button {
			margin: 0;
			width: calc(50% - 0.5rem);
		}
	}
`

const Bar = styled.div`
	position: fixed;
	bottom: 0rem;
	left: 0;
	width: 100%;
	padding: 2rem 3rem;
	z-index: 999;
	line-height: 1.5;

	color: white;
	border: 1px solid rgba(100, 91, 155, 0.5);
	background: #121212;
	backdrop-filter: blur(50px);
	overflow: hidden;

	font-family: var(--font);
	font-size: 1rem;
`


export default CookieBar