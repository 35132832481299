import React, { useState, useEffect, useContext, createContext } from "react"
import Cookies from "js-cookie"


const cookieContext = createContext()

export function CookieProvider({ children }) {
	const cookie = useCookieProvider()
	return <cookieContext.Provider value={cookie}>{children}</cookieContext.Provider>
}

export const useCookies = () => {
	return useContext(cookieContext)
}

export const cookieConfig = {
	expires: 365,
	path: '/'
	// requires domain to be set client side
}

export const cookieName = "skyweaver-cookie-approval"
export const REJECT = "REJECT"
export const ACCEPT = "ACCEPT"
export const cookieText = `Skyweaver uses cookies and similar technologies to provide you with personalized content, improve site performance, and conduct analytics. By using the Skyweaver website or other online services, you consent to the practices described in our <a target="_blank" href="https://horizon.io/privacy">Privacy Policy</a> and <a target="_blank" href="https://www.skyweaver.net/terms-of-service/">Terms of Service</a>.`

const useCookieProvider = () => {
	// options include ACCEPT or REJECT
	// Need to wait for cookie response before making any actions
	const [response, setResponse] = useState(null)
	const [displayBar, setDisplayBar] = useState(false)

	useEffect(() => {
		cookieConfig.domain = window.location.hostname
		const initialCheck = Cookies.get(cookieName)

		// show the cookie bar until someone explicitly makes an action
		if (!initialCheck) {
			setDisplayBar(true)
			// setResponse(ACCEPT)
		} else {
			// either REJECT or ACCEPT
			setResponse(initialCheck)
		}
	}, [])

	const rejectCookies = () => {
		action(REJECT)
	}

	const acceptCookies = () => {
		action(ACCEPT)
	}

	const resetCookie = () => {
		Cookies.remove(cookieName)
	}

	const action = (option) => {
		Cookies.set(cookieName, option, cookieConfig)
		setResponse(option)
		setDisplayBar(false)
	}

	return {
		cookieConfig,
		cookieText,
		ACCEPT,
		REJECT,
		response,
		rejectCookies,
		acceptCookies,
		resetCookie,
		displayBar,
		rejectsCookies: response !== ACCEPT
	}
}