import React, { useState, useEffect, useContext, createContext, useRef } from "react"

const scrollContext = createContext()

export function ScrollProvider({ children }) {
	const cookie = useScrollProvider()
	return <scrollContext.Provider value={cookie}>{children}</scrollContext.Provider>
}

export const useScroll = () => {
	return useContext(scrollContext)
}


export const DOWN = 0
export const UP = 1
export const THRESHOLD = 600

const useScrollProvider = () => {
	const [direction, setDirection] = useState(UP)
	const lastScroll = useRef(null)
	const [showBg, setBg] = useState(false)

	useEffect(() => {
		lastScroll.current = 0

		const handleScroll = () => {
			setBg(window.scrollY > 100)

			if (window.scrollY > THRESHOLD) {
				setDirection(lastScroll.current > window.scrollY ? UP : DOWN)
				lastScroll.current = window.scrollY
			} else {
				setDirection(UP)
			}
		}


		handleScroll()

		window.addEventListener('scroll', handleScroll, true)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return {
		direction,
		showBg
	}
}