import React from "react"
import { CookieProvider } from "utils/useCookies"
import { ScrollProvider } from "utils/useScroll"
import CookieBar from "components/Cookie/CookieBar"

// wrap the entire website w the cookie Provider so we have 
// access to cookie functions
// as if this was the App.js main component that get rendered oncce
export const wrapWithProvider = ({ element }) => {
	return <CookieProvider>
		<ScrollProvider>
			{element}
		</ScrollProvider>
		<CookieBar />
	</CookieProvider>
}
